





import Vue from 'vue';
import UserAccountsPage from './UserAccount.vue'
export default Vue.extend({
  name: 'AccountsIndex',
  components: {
    UserAccountsPage
  }
});
